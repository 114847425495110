<template>
  <div id="app">
<!--    <Header></Header>-->
<!--    <Container></Container>-->
<!--    <HomeView></HomeView>-->
<!--    <keep-alive>-->
      <router-view v-if="isRouterAlive"></router-view>
<!--    <bottom-banner></bottom-banner>-->

  </div>

</template>

<script>
import Container from "@/components/Container.vue";
import HomeView from "@/views/HomeView.vue";
import HeaderTest from "@/components/HeaderTest.vue";


export default {
  components:{
    HeaderTest,
    HomeView,
    // BottomBanner:()=> import('../src/components/BottomBanner.vue')
  },
  provide(){
    return{
      reload:this.reload
    }
  },
  data(){
    return {
      isRouterAlive:true
    }
  },
  methods:{

    reload(){
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    }
  }
}

</script>
<style>
ul{
  list-style: none;
}
*{
  padding: 0;
  margin: 0;
}
.bigtitle-mycourse{
  width: 70%;
  margin: 110px auto 0;
  height: 100px;
  display: flex;
  align-items: center;
  background-color: #f6ad53;
  border-radius: 12px; /* 边框圆角 */
  padding-left: 0;
  background-image: url("~@/assets/background.png");
  background-size: 100% auto;
}
@media (max-width: 1000px) {
  .bigtitle-mycourse{
    width: 98%;
    margin-top: 70px;
  }
  .customWidth{
    width: 98% !important;
  }
}
</style>

