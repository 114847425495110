<template>
  <div class="naviBar">
    <div class="naviContent_window">
      <div class="navi_phone" @click.stop>
        <img src="../assets/navi-icon.png" style="width: 24px;height: 24px;cursor: pointer" @click.stop="showNaviPullDown()">
        <div v-if="naviPullDownVisable" class="naviPullDown" ref="treeWrap" @click.stop>
          <p class="phoneNaviItem" style="font-size: 20px;font-weight: bold;line-height: 28px;"  @click="toIndex">首页</p>
          <p class="phoneNaviItem" @click="linkMoreCourses">课程一览</p>
          <p class="phoneNaviItem" @click="linkabout">关于我们</p>
          <p class="phoneNaviItem" @click="linkcontactus">联系我们</p>
        </div>
      </div>
      <div class="logo_phone" @click="toIndex">
        <img src="../assets/logo.jpg" alt="logo" style="width: 80px;height: 40px">
      </div>
      <div class="logo-item">
        <img src="../assets/logo.jpg" alt="DreamEase" class="logo" @click="toIndex">
        <div class="naviButtonGroup">
          <div>
            <span :class="{ activePwd: pwd === '1' }" style="padding-top: 1px" @click="toIndex">首页</span>
            <span :class="{ activePwd: pwd === '2' }" @click="linkMoreCourses">课程一览</span>
            <span :class="{ activePwd: pwd === '3' }" @click="linkabout">关于我们</span>
            <span :class="{ activePwd: pwd === '4' }" @click="linkcontactus">联系我们</span>
          </div>
        </div>
      </div>
      <div v-if="!isLogin" class="loginWindows_unlogin">
        <span id="login" @click="good">登录</span>
        <button id="register" style="background-color: #EB5D17" @click="redirectToRegistration('student')">学生注册</button>
        <button id="register" style="background-color: #F6AD53" @click="redirectToRegistration('teacher')">教师注册</button>
      </div>
      <div v-if="!isLogin" class="loginPhone_unlogin">
        <img src="../assets/loginicon.png" alt="loginicon" style="width: 24px;height: 24px" @click="showPhoneLoginMenu">
        <div v-if="phoneLoginMenuVisible" class="phoneLoginMenu">
          <div class="phoneLoginMenuItem" style="padding-top: 6px">
            <img src="../assets/loginPhone.png" alt="loginPhone">
            <span @click="good">登录</span>
          </div>
          <div class="phoneLoginMenuItem" style="margin: 8px auto">
            <img src="../assets/teacherRegisterPhone.png" alt="loginPhone">
            <span @click="redirectToRegistration('teacher')">教师注册</span>
          </div>
          <div class="phoneLoginMenuItem" style="margin: 8px auto">
            <img src="../assets/studentRegisterPhone.png" alt="loginPhone">
            <span @click="redirectToRegistration('student')">学生注册</span>
          </div>



        </div>

      </div>
      <div v-if="isLogin" class="loginWindows_login" >
        <button class="publishClassButton" @click="publishCourse" v-if="userData.role==='tea'">发布课程</button>
        <div class="userInfo"  @click.stop="showNavi">
          <img  class="userProfile" :src="userData.profile" alt="userProfile">
          <div class="userInfo-detail" id="userInfoScreen">
            <p class="login_username">{{userData.name}}</p>
            <p class="login_role" v-if="userData.role==='tea'">教师</p>
            <p class="login_role" v-if="userData.role==='stu'">学生</p>
          </div>
<!--          v-if="showPullDown"-->
          <div v-if="showPullDown" class="dropdownMenu" ref="dropdownMenu">
            <div style="height: 80px;display: flex;align-items: center;justify-content: center">
              <img  :src="userData.profile" style="width: 40px;height: 40px;margin-right: 10px;cursor: pointer;border-radius: 20px" alt="userProfile">
              <span>{{userData.name}}</span>
            </div>

            <div style="display: flex;margin-bottom: 23px">
              <div class="personalInfo" @click="linkmycourse">
                <p class="personalInfoNumber">{{subscribeNum}}</p>
                <p class="personalInfoLable">我的关注</p>
              </div>
              <div class="personalInfo" @click="linkMessageCenter">
                <p class="personalInfoNumber" v-if="newMessageNum>0">
                  <el-badge :value="newMessageNum" is-dot :max="99">{{messageNum}}</el-badge>
                </p>
                <p class="personalInfoNumber" v-if="newMessageNum===0">
                  {{messageNum}}
                </p>
                <p class="personalInfoLable">我的消息</p>
              </div>
            </div>

            <div class="menuLabel" @click="linkMypage">
              <i class="el-icon-user" > 个人中心</i>
              <i class="el-icon-arrow-right"></i>
            </div>
            <div class="menuLabel" style="border-bottom: 1px solid #D9D9D9;" v-if="userData.role==='stu'" @click="linkpaidcourse">
              <i class="el-icon-notebook-2"> 课程中心</i>
              <i class="el-icon-arrow-right"></i>
            </div>
            <div class="menuLabel" v-if="userData.role==='tea'" @click="publishCourse">
              <i class="el-icon-edit"> 发布课程</i>
              <i class="el-icon-arrow-right"></i>
            </div>
            <div class="menuLabel" style="border-bottom: 1px solid #D9D9D9;padding-bottom: 20px" v-if="userData.role==='tea'" @click="linkmanagecourse">
              <i class="el-icon-notebook-2"> 已发布课程</i>
              <i class="el-icon-arrow-right"></i>
            </div>
            <div class="menuLabel" style="padding: 10px 0" @click="exit">
              <power-off></power-off>
            </div>
          </div>
        </div>

      </div>

    </div>




    <el-dialog :append-to-body="true" title="登陆" customClass="customWidth" :visible.sync="dialogFormVisible" :center="true" width="30%" >
      <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="20%" class="demo-ruleForm" @keyup.enter.native="submitForm('ruleForm')">
        <el-form-item label="邮箱" prop="email">
          <el-input prefix-icon="el-icon-s-custom" type="account" v-model="ruleForm.email" style="width: 98%" placeholder="请输入邮箱登陆"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="pass">
          <el-input prefix-icon="el-icon-lock" type="password" v-model="ruleForm.pass" style="width: 98%" placeholder="请输入密码" ></el-input>
        </el-form-item>

        <el-form-item>
          <div style="display: flex;justify-content: center">
            <el-button type="warning" @click="submitForm('ruleForm')">登陆</el-button>
            <div style="margin-left: 50px;text-decoration: underline;cursor: pointer" @click="routePassReset()">忘记密码</div>
          </div>
        </el-form-item>
      </el-form>

    </el-dialog>
  </div>
</template>


<script>
import request from "@/utils/request";
import Verify from "vue2-verify";
import {mapState} from "vuex";
import {encryptData,decryptData} from "@/utils/encryptUtils";
import powerOff from "@/components/svg/powerOff.vue";
export default {
  name:'HeaderTest',
  components: {Verify,powerOff},
  props: {
    message: {
      type: Boolean,
      required: true
    },
    pwd: {
      type: String,
      required: false
    }
  },
  data() {
    var validateEmail = (rule, value, callback) => {
      const mailReg = /^[a-zA-Z0-9._%+-]+@(163\.com|126\.com|qq\.com|gmail\.com|outlook\.com)$/
      if (!value) {
        return callback(new Error('邮箱不能为空'))
      }
      setTimeout(() => {
        if (mailReg.test(value)) {
          callback()
        } else {
          callback(new Error('请输入163,126,qq,gmail,outlook邮箱'))
        }
      }, 100)
    }
    var validatePass = (rule, value, callback) => {
      var numStr = value.toString();
      if (numStr.length<=6 || numStr.length>15){
        callback(new Error('密码应在6到15个字之间'));
      }
      setTimeout(() => {
        if (value) {
          callback()
        } else {
          callback(new Error('请输入密码'))
        }
      }, 100)
    }
    var validateToken = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请进行人机认证'))
      }
      setTimeout(() => {
        if (value) {
          callback()
        } else {
          return callback(new Error('请进行人机认证'))
        }
      }, 100)
    }
    return {
      registerShow:false,
      keyword:'',
      userData:{
        name:'',
        role:'',
        profile:'',
        token:''
      },
      activeIndex: '1',
      activeIndex2: '1',
      role:'',
      showPullDown:false,
      messageNum:0,
      newMessageNum:0,
      dialogFormVisible:false,
      ruleForm: {
        email: '',
        pass: '',
        token: ''
      },
      subscribeNum:0,
      naviPullDownVisable:false,
      circleUrl: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      isLoginHeader:false,
      // isLogin:false,
      userMobile:{
        name:'',
        role:'',
        profile:'',
        token:''
      },
      rules: {
        email: [
          { require:true,validator:validateEmail, trigger: 'blur' }
        ],
        pass: [
          { require:true,validator:validatePass, trigger: 'blur' }
        ],
        token: [
          { require:false,validator:validateToken, trigger: 'blur' }
        ],
      },
      isNavVisible: false,
      phoneLoginMenuVisible:false
    };
  },
  methods: {
    showNaviPullDown(){
      this.naviPullDownVisable= !this.naviPullDownVisable
    },
    showPhoneLoginMenu(){
      this.phoneLoginMenuVisible = !this.phoneLoginMenuVisible
    },

    redirectToRegistration(role) {
      this.$router.replace('/k').then(() => {
        // 然后跳转到目标路由
        this.$router.push('/register/'+role);
      });

    },
    handleClickOutsideProfile(event) {
      const dropdownMenu = this.$refs.dropdownMenu;
      if (dropdownMenu && !dropdownMenu.contains(event.target)) {
        this.showPullDown = false;
      }
    },
    routeRegister(){
      this.registerShow = true
    },
    routePassReset(){
      this.$router.push("/password/reset")
    },
    async onVerifySuccess() {
      //验证码正确回调
      await grecaptcha.ready(() => {
        grecaptcha.execute('6Ld7EOApAAAAAOHsTchGC0jYmwJ-fz0ZYaVGVHQI', {action: 'login'}).then((token) => {
          this.ruleForm.token = token
        });
      });
    },
    onVerifyError(obj) {
      //验证码错误回调
      console.log('verify error');
      //错误刷新验证码
      obj.refresh();
      //todo
    },
    search(){
      if (this.keyword != null){
        this.$router.push('/search/'+this.keyword)
      }

    },
    linkMypage(){
      this.$router.push('/mypage')
    },
    good(){
      this.dialogFormVisible = true

    },
    publishCourse(){
      this.$router.push('/publish')
    },
    linkMessageCenter(){
      this.$router.push('/message')
    },
    linkcontactus(){
      this.$router.push('/contactus')
    },
    linkMoreCourses(){
      this.$router.push('/courses')
    },
    exit(){
      localStorage.removeItem('honey-user');
      this.isLogin=false
      this.showPullDown= false
      this.$store.commit('setLoginValue',false)
      this.$router.push("/")
    },
    toIndex(){
      this.$router.push("/")
    },
    linkabout(){
      this.$router.push("about")
    },
    showNavi(){
      // var menu = document.querySelector('.nav-menu');
      // if (menu.style.display === "block") {
      //   menu.style.display = "none";
      // } else {
      //   menu.style.display = "block";
      // }
      this.showPullDown = !this.showPullDown
    },
    linkmycourse(){
      this.$router.push("/mycourse")
    },
    linkpaidcourse(){
      this.$router.push("/paidcourse")
    },
    linkmanagecourse(){
      this.$router.push("/managecourse")
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          request.post("student/login",this.ruleForm).then(res=>{
            if (res.data.code==='200'){
              this.$message.success({
                message:"登录成功",
                duration:2000
              });
              //将未加密的返回值放入userData中，在界面展示
              this.userData  = res.data.data
              if (this.userData.profile==='1'){
                this.userData.profile = this.circleUrl
              }else {
                this.userData.profile += "?tempid="+Math.random()
              }
              //加密userData，放入localStorage
              const encryptUserData = encryptData(res.data.data);
              localStorage.setItem("honey-user",encryptUserData);
              this.dialogFormVisible = false
              //登录状态存起来
              this.$store.commit('setLoginValue',true)
            }else {
              //test用
              this.$message.error(res.data.msg);
            }
          }).catch(error=>{
            console.log(error);
          })
        } else {

          return false;
        }
      });
    },
    listMessage(){
      request.get("message/listMessageNumber").then(res=>{
        if (res.data.code==='200'){
          if (res.data.data!=0){
            console.log(res.data.data)
            this.newMessageNum = res.data.data.newMessageNum
            this.messageNum = res.data.data.messageNum
            this.subscribeNum = res.data.data.subscribeNum
          }else{
            this.newMessageNum = 0
          }

        }

      }).catch(err=>{
        console.log(err)
      })
    },
    handleClickOutside(event) {
      if (this.$refs.treeWrap && !this.$refs.treeWrap.contains(event.target)) {
        this.naviPullDownVisable = false;
      }
    },
  },
  async mounted() {
    document.addEventListener('click', this.handleClickOutsideProfile);
    document.addEventListener('click', this.handleClickOutside);
    let flag = localStorage.getItem("honey-user")
    if (flag!= null){
      if (this.message){
        this.listMessage()
      }
      this.userData=decryptData(flag)
      this.$store.commit('setLoginValue',true)
    }else {
      this.$store.commit('setLoginValue',false)
    }
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
    document.removeEventListener('click', this.handleClickOutsideProfile);

  },
  watch:{
    pwd(newVal, oldVal){

    }
  },
  computed:{
    isLogin:{
      get() {
        // this.userData = decryptData(localStorage.getItem('honey-user'))
        return  JSON.parse(this.$store.state.loginValue)
      },
      set(value) {
        this.loggedIn = value;
      }

    },
  }
}
</script>
<style scoped>
.naviBar{
  width: 100vw;
  height: 100px;
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  border-bottom: 1px solid #dcdfe6;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: white;

}
.naviContent_window{
  display: flex;
  width: 100%;
  max-width: 1200px;
  height: 100%;
  align-items: center; /* 垂直居中 */
  justify-content: space-between;
}
.logo-item{
  width: 49%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.logo{
  width: 113px;
  height: 60px;
  cursor: pointer;
}

.naviButtonGroup {
  width: 70%;
  max-width: 408px;
  font-family: 'PingFang SC', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 38px;
  text-align: left;
}

.naviButtonGroup > div {
  display: flex;
  justify-content: space-between; /* 修改为space-around以提供适当间隔 */
  width: 100%;
}

.naviButtonGroup > div > span {
  cursor: pointer;
  text-align: center; /* 中心对齐文本 */
  color: #1A1A1A;
  border-bottom: 4px solid transparent;

}
.naviButtonGroup span.activePwd {
  border-bottom: 4px solid #EB5D17; /* Change the color as needed */
}

.naviButtonGroup > div > span:hover {
  border-bottom: 4px solid #F6AD53; /* 鼠标悬停时改变边框颜色 */
}


.loginWindows_unlogin{
  width: 310px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.loginWindows_login{
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;

}
#login{
  font-family: PingFang SC, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #EB5D17;
  border-bottom: 4px solid transparent;
  cursor: pointer;
}
#register{
  height: 50px;
  width: 104px;
  border-radius: 8px;
  border: 0 solid transparent;
  color: white;
  font-size: 16px;
  font-weight: 400;
  font-family: PingFang SC, sans-serif;
  cursor: pointer;

}
.publishClassButton{
  height: 40px;
  width: 104px;
  border-radius: 8px;
  border: 0 solid transparent;
  color: white;
  font-size: 16px;
  font-weight: 400;
  font-family: PingFang SC, sans-serif;
  background-color: #F6AD53;
  cursor: pointer;
}
.userInfo{
  display: flex;
  margin-left: 30px;
  position: relative;
}
.dropdownMenu {
  position: absolute;
  top: 50px;
  left: -60px;
  background: white;
  box-shadow: 1px 2px 5px 1px rgba(0, 0, 0, 0.2);
  width: 200px;
  z-index: 10;
  border-radius: 10px;
  border: 2px solid #FFFFFF;

}
.userProfile{

}
.userInfo-detail{
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 3px;
}
.login_username{
  font-family: PingFang SC, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  text-align: right;
  max-width: 120px;
  white-space: nowrap;    /* 防止文本换行 */
  overflow: hidden;       /* 隐藏溢出内容 */
  text-overflow: ellipsis; /* 用省略号表示溢出部分 */
}
.login_role{
  font-family: PingFang SC, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  text-align: left;
  color: #777777;
  padding-top: 3px;

}
.personalInfo{
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.personalInfoNumber{
  font-family: PingFang SC sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
}
.personalInfoLable{
  font-family: PingFang SC sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;
  color: #777777;
}
.menuLabel{
  font-size: 14px;
  font-family: PingFang SC sans-serif;
  font-weight: 400;
  line-height: 19.6px;
  color: #1A1A1A;
  width: 80%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding: 10px 0;
  cursor: pointer;
}
.navi_phone{
  display: none;
}
.logo_phone{
  display: none;
}
.loginPhone_unlogin{
  display: none;
}
.userProfile{
  width: 50px;
  height: 50px;
  margin-right: 10px;
  cursor: pointer;
  border-radius: 25px;
}

@media (max-width: 1000px){
  .logo-item{
    display: none;
  }
  .naviBar{
    height: 60px;
  }
  .naviContent_window{
    width: 95%;
    height: 100%;
  }
  .logo_phone{
    display: block;
    cursor: pointer;
  }
  .navi_phone{
    display: block;
    position: relative;
  }
  .naviPullDown{
    position: absolute;
    width: 160px;
    height: calc(100vh - 60px);
    background-color:#FFFFFFED;
    left: calc(-3vw);
    font-family: Alibaba PuHuiTi sans-serif;
    text-align: center;

  }
  .phoneNaviItem{
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    margin-left: 40px;
    margin-top: 28px;
    padding-top: 3px;
    padding-bottom: 3px;
    cursor: pointer;

  }
  .userInfo{
    margin-left: 0;
    position: relative;
  }
  .loginWindows_unlogin{
    display: none;
  }
  .loginPhone_unlogin{
    display: block;
    position: relative;
  }
  .phoneLoginMenu {
    position: absolute;
    top: 30px;
    right: 0px;
    background: white;
    box-shadow: 1px 2px 5px 1px rgba(0, 0, 0, 0.2);
    width: 108px;
    height: 130px;
    z-index: 10;
    border-radius: 14px;
    border: 2px solid #FFFFFF;

  }
  .phoneLoginMenuItem{
    width: 92px;
    height: 32px;
    display: flex;
    align-items: center;
    margin: 0 auto;
    cursor: pointer;
  }
  .phoneLoginMenuItem img{
    width: 20px;
    height: 20px;
  }
  .phoneLoginMenuItem span{
    line-height: 16px;
    margin-left: 6px;

  }
  #userInfoScreen{
    display: none;
  }
  .dropdownMenu {
    left: -160px;
  }
  .publishClassButton{
    display: none;
  }

  .userProfile{
    width: 40px;
    height: 40px;
  }
}

</style>