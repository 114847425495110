<template>
  <div>
    <HeaderTest :message="true" :pwd="'2'"></HeaderTest>


    <div class="middle">
      <div class="searchDiv">
        <div class="search-container">
          <input type="text" placeholder="学校・专业课・研究计划书名称" v-model="keyword">
          <button @click="search"> 搜索 </button>
        </div>
      </div>
<!--      <div class="hero">-->
<!--        <div class="carousel">-->
<!--          <el-carousel :height="elheight">-->
<!--            <el-carousel-item v-for="(item,index) in imageList" :key="index" class="imageItemDisplay">-->
<!--              <img :src=item class="pictureShow" alt="平台介绍" @click="linkPage(index)">-->
<!--            </el-carousel-item>-->
<!--          </el-carousel>-->
<!--        </div>-->
<!--        <div class="login"><Login :showLn="showLn"></Login></div>-->
<!--      </div>-->
      <div class="mainDisplay">
        <display-course :courseType="courseType"></display-course>
      </div>

    </div>
    <bottom-banner></bottom-banner>
<!--    <div class="mainFooter">-->
<!--      <div class="mainFooter1">-->
<!--&lt;!&ndash;        <div class="contact1">&ndash;&gt;-->
<!--&lt;!&ndash;          <div  @click="linkIntroduction()">平台介绍</div>&ndash;&gt;-->
<!--&lt;!&ndash;          <div  @click="linkcontactUs()">联系我们</div>&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--        <div class="concept">-->
<!--          <p style="font-size: 16px;font-weight: 550">About DREAMEASE</p>-->
<!--          <p style="font-size: 14px;line-height:30px;margin-top: 20px">DREAMEASE是一款专注日本考学的创新教育平台，我们致力于突破传统的中介私塾考学模式，通过将教师与学生直接连接匹配，实现双方利益最大化。-->
<!--            </p>-->
<!--        </div>-->
<!--        <div class="contact">-->
<!--          <div style="font-size: 14px">-->
<!--            <div  @click="linkIntroduction()" style="text-decoration: underline;cursor: pointer">平台介绍</div>-->
<!--            <div  @click="linkcontactUs()" style="text-decoration: underline;cursor: pointer">联系我们</div>-->
<!--            <div  @click="linktagreement()" style="text-decoration: underline;cursor: pointer">教师用户协议</div>-->
<!--            <div  @click="linksagreement()" style="padding-bottom: 0;text-decoration: underline;cursor: pointer">学生用户协议</div>-->
<!--          </div>-->

<!--        </div>-->
<!--&lt;!&ndash;        <div class="wechatqr">&ndash;&gt;-->
<!--&lt;!&ndash;          <div style="color: white;width: 100%">&ndash;&gt;-->
<!--&lt;!&ndash;            <p style="margin-bottom: 10px" class="wechatReceiver">客服微信</p>&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="qrcontain" style="display: flex;">&ndash;&gt;-->
<!--&lt;!&ndash;              <div style="margin-right: 50px">&ndash;&gt;-->
<!--&lt;!&ndash;                <img src="../images/contactQRconsult.png" style="width: 100px; height: 100px">&ndash;&gt;-->
<!--&lt;!&ndash;                <p style="text-align: center;font-size: 15px">网站客服</p>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--&lt;!&ndash;              <div>&ndash;&gt;-->
<!--&lt;!&ndash;                <img src="../images/contactQR.png" style="width: 100px; height: 100px">&ndash;&gt;-->
<!--&lt;!&ndash;                <p style="text-align: center;font-size: 15px">网站技术问题</p>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->



<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--      </div>-->

<!--    </div>-->
<!--    <div class="copyRight" style="background-color: #595A5DFF;">-->
<!--      <p style="text-align: center;color: white;padding: 5px 0 5px 0;font-size: 14px">Copyright © 2023 - 2024 dreamease.jp</p>-->
<!--    </div>-->
  </div>


</template>

<script>
// import Login from "@/components/subComponents/login.vue";
import DisplayCourse from "@/components/subComponents/displayCourse.vue";
import HeaderTest from "@/components/HeaderTest.vue";
import BottomBanner from "@/components/BottomBanner.vue";

export default {
  name: 'ContainerTest',
  computed: {
    // contactUs() {
    //   return contactUs
    // }
  },

  components:{
    BottomBanner,
    HeaderTest,
    DisplayCourse,
  },
  props:{
    showLn:false
  },
  data(){
    return{
      imageList:[
          // "https://courseimagedreamease.s3.ap-northeast-1.amazonaws.com/intorducePlatform.png",
          "https://d27dg1guodsgxw.cloudfront.net/introImg.jpg"
      ],
      elheight: "450px",
      windowWidth: window.innerWidth,
      courseType: "0",
      keyword:''
    }
  },
  mounted() {
    //this.courseType = this.$route.query.courseType
    // console.log(this.$route.query.courseType,"courseType")
    // if (this.$route.query.courseType !== undefined){
    //   this.courseType = this.$route.query.courseType
    // }


  },
  created() {
    // if (typeof window !== 'undefined') {
    //   const width = window.innerWidth;
    //   if (width<=1152){
    //     this.elheight = "300px"
    //   }
    // }
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods:{
    search(){
      if (this.keyword != null){
        this.$router.push('/search/'+this.keyword)
      }

    },

    handleResize() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth<1152){
        this.elheight = "300px"
    }else{
        this.elheight = "450px"
      }
  },
    closeNaviLeft(){
      const menuIcon = document.querySelector('.el-icon-s-fold');
      const navMenu = document.querySelector('.nav-menu');
      console.log("hi")
      if (navMenu.style.display === 'block') {
        navMenu.style.display = 'none';
      }

    },
    linkPage(key){
      if (key==0){
        this.$router.push("/platformIntro")
      }

    }
  }
}
</script>
<style>
.middle{
  width: 70%;
  margin: 130px auto 0;
  flex-direction: column;
}
.hero{
  display: flex;
  justify-content: space-between;
  margin: auto;
}
.carousel{
  flex: 0 1 68%;
}

.login{
  flex: 0 1 30%;
}
.pictureShow{
  width: 100%;
  height: 450px;
  cursor: pointer;
}

/*主显示区*/
.mainDisplay{
  margin-top: 50px;
}
.mainFooter{
  background-color: rgba(236, 175, 88, 0.98);
  display: flex;
  padding-bottom: 10px;
  font-weight: 540;
}
.mainFooter1{
  display: flex;
  width: 60%;
  margin: 0 auto;
}
.concept{
  flex: 3;
  padding: 30px 10px 20px 50px;

}
.contact{
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.contact > div > div{
  padding-bottom: 10px;
}

.wechatqr{
  flex: 3;
  display: flex;
  align-items: center;
  padding-left: 50px;
}
.contact1{
  display: none;
}
.searchDiv{
  margin-top: 60px;
}
.search-container {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  width: 60%;
  height: 50px;
  margin: 0 auto;
  box-shadow: rgb(246, 173, 83)
}
.search-container input[type="text"] {
  border: none;
  padding: 10px;
  flex: 1;
  outline: none;
}
.search-container button {
  background-color: #F6AD53 ;
  border: none;
  color: white;
  padding: 10px 30px;
  cursor: pointer;
  height: 62px;
}
.search-container button:hover {
  background-color: #EB5D17;
}
@media screen and (max-width: 1152px){


  .middle{
    width: 98%;
    margin-top: 100px;
  }

  .pictureShow{
    height: 300px;
  }
  .imageItemDisplay{
    display: block;
  }
  .mainFooter1{
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .concept{
    padding-top: 10px;
  }
  .contact >div{
    display: flex;
    flex-direction: row;
  }
  .contact >div > div{
    padding-bottom: 0;
    padding-right: 10px;
  }
  .contact1{
    display: flex;
    justify-content: center;
    padding-top: 10px;
    flex-direction: row;
  }
  .contact1 > div{
    margin-right: 10px;

  }
  .contact1 > div:hover{
    cursor: pointer;
    text-decoration: underline;


  }
  .qrcontain{
    display: flex;
    justify-content: center;
  }
  .wechatReceiver{
    display: none;
  }
  .searchDiv{
    margin-top: 10px;
  }
  .search-container {
    width: 90%;
  }
  .mainDisplay{
    margin-top: 30px;
  }

}



</style>