<!-- SvgIcon.vue -->
<template>
  <div class="icon-wrapper">
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_6_1373)">
      <path d="M9.55106 3.03894C9.48542 2.99947 9.4282 2.94746 9.38266 2.88587C9.33712 2.82429 9.30416 2.75433 9.28565 2.68001C9.26715 2.60568 9.26346 2.52844 9.27481 2.45269C9.28616 2.37695 9.31231 2.30417 9.35178 2.23853C9.39125 2.17289 9.44326 2.11567 9.50485 2.07013C9.56643 2.02459 9.63639 1.99163 9.71071 1.97312C9.78504 1.95462 9.86228 1.95093 9.93803 1.96228C10.0138 1.97363 10.0865 1.99978 10.1522 2.03925C11.0593 2.58329 11.81 3.35313 12.331 4.27369C12.852 5.19425 13.1256 6.23411 13.125 7.29188C13.125 10.6746 10.3827 13.4169 7 13.4169C3.61725 13.4169 0.875 10.6746 0.875 7.29188C0.875 5.11444 2.02125 3.13475 3.85394 2.03531C3.98668 1.95577 4.14558 1.93222 4.29568 1.96984C4.44579 2.00746 4.5748 2.10317 4.65434 2.23591C4.73388 2.36865 4.75744 2.52755 4.71982 2.67766C4.6822 2.82776 4.58649 2.95677 4.45375 3.03631C3.71781 3.47637 3.10867 4.09988 2.6859 4.84589C2.26313 5.59189 2.0412 6.43484 2.04181 7.29231C2.04181 10.0302 4.26125 12.2501 7 12.2501C9.73875 12.2501 11.9582 10.0302 11.9582 7.29188C11.9582 5.5305 11.0333 3.93013 9.55106 3.03938V3.03894ZM7 0.583252C7.322 0.583252 7.58319 0.84444 7.58319 1.16688V5.83325C7.58319 5.98792 7.52174 6.13626 7.41238 6.24563C7.30301 6.355 7.15467 6.41644 7 6.41644C6.84533 6.41644 6.69699 6.355 6.58762 6.24563C6.47826 6.13626 6.41681 5.98792 6.41681 5.83325V1.16688C6.41681 0.84444 6.678 0.583252 7 0.583252Z" fill="#A7ACBA"/>
    </g>
    <defs>
      <clipPath id="clip0_6_1373">
        <rect width="14" height="14" fill="white"/>
      </clipPath>
    </defs>
  </svg>
  <span class="logout-text">退出登录</span>
  </div>
</template>

<script>
export default {
  name: 'poerOff'
}
</script>

<style scoped>
.icon-wrapper {
  display: flex;
  align-items: center;
}

.logout-text {
  margin-left: 8px;
  font-size: 14px;
  color: #A7ACBA;
  cursor: pointer;
  font-family: PingFang SC sans-serif;
  font-weight: 400;
  line-height: 19.6px;
  text-align: left;

}
</style>
