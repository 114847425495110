import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loginValue: false,
    user: {
      token: '',
      role: '',
      profile: '',
      name: ''
    }
  },
  getters: {
  },
  mutations: {
    setLoginValue(state, value) {
      state.loginValue = value;
    },
    setUser(state, userData) {
      state.user = { ...state.user, ...userData };
    }
  },
  actions: {
  },
  modules: {
  }
})
