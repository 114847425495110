<template>
  <div class="bottomBannerContainer">
    <div class="innerContainer">
      <div class="img_navi">
        <img src="../assets/logo.jpg" id="bottomLogo" alt="logo" style="width: 113px;height: 60px">
        <div class="bottom_navi_container">
          <span class="bottomLinkItem" @click="toIndex">首页</span>
          <span class="bottomLinkItem" @click="linkMoreCourses">课程一览</span>
          <span class="bottomLinkItem" @click="linkIntroduction">关于我们</span>
          <span @click="linkcontactUs">联系我们</span>
          <p>Copyright © 2023 - 2024 dreamease.jp</p>
        </div>
      </div>
      <div class="contactQRCode">
        <div>
          <img src="../assets/redbook.png" alt="redbook">
          <p>小红书</p>
        </div>
<!--        <div>-->
<!--          <img src="../assets/bilibili.png" alt="bilibili">-->
<!--          <p>BiliBili</p>-->
<!--        </div>-->
        <div>
          <img src="../assets/wechat.png" alt="wechat">
          <p>微信</p>
        </div>
      </div>
    </div>


  </div>
</template>
<script>
export default {
  name: 'BottomBanner',
  methods:{
    toIndex() {
      this.$router.push("/")
    },
    linkMoreCourses(){
      this.$router.push('/courses')
    },
    // linktagreement(){
    //   this.$router.push("/tagreement")
    // },
    // linksagreement(){
    //   this.$router.push("/sagreement")
    // },
    linkcontactUs(){
      this.$router.push('/contactus')
    },
    linkIntroduction(){
      this.$router.push('/about')
    },
  }
}

</script>
<style scoped>
.bottomBannerContainer{
  width: 100%;
  height: 208px;
  display: flex;
  justify-content: space-between;
}
.innerContainer{
  width: 100%;
  height: 100%;
  max-width: 1200px;
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
}
.img_navi{
  display: flex;
}
.img_navi div{
  margin-left: 40px;
  padding: 6px 0
}
.img_navi p{
  margin-top: 10px;
  color: #999999;
  font-size: 14px;
  line-height: 16px
}
.bottomLinkItem::after {
  content: "|";
  height: 16px; /* 设定右边边框的长度 */
  padding:  0 16px 0 16px;

}
.contactQRCode{
  display: flex;
  text-align: center;
}

.contactQRCode div img{
  width: 100px;
  height: 100px;
}
.contactQRCode div:nth-child(1),
.contactQRCode div:nth-child(2){
  margin-right: 30px;
}
.bottom_navi_container span{
  cursor: pointer;
}
@media (max-width: 1000px){
  #bottomLogo{
    display: none;
  }
  .innerContainer{
    width: 100%;
    height: 208px;
    display: block;
    text-align: center;
  }
  .img_navi{
    flex-direction: column;
    align-items: center;
  }
  .bottom_navi_container{
    margin-top: 0;
  }
  .img_navi div{
    margin-left: 0;
  }

  .bottom_navi_container p{
    padding-top: 6px;
  }
  .contactQRCode{
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .contactQRCode div:nth-child(1){
    margin-right: 20px;
  }
}
</style>