<template>
  <div>
    <div class="classification">
      <div class="item">
        <el-radio-group fill="#f6ad53" v-model="selectedButton">
          <el-radio-button label="all" class="classification-button">全部</el-radio-button>
          <el-radio-button label="研究计划书" class="classification-button">研究计划书</el-radio-button>
          <el-radio-button label="大学院文科" class="classification-button">大学院文科</el-radio-button>
          <el-radio-button label="大学院理科" class="classification-button">大学院理科</el-radio-button>
          <el-radio-button label="学部文科" class="classification-button">学部文科</el-radio-button>
          <el-radio-button label="学部理科" class="classification-button">学部理科</el-radio-button>
          <el-radio-button label="艺术" class="classification-button">艺术</el-radio-button>
          <el-radio-button label="日语" class="classification-button">日语</el-radio-button>
          <el-radio-button label="英语" class="classification-button">英语</el-radio-button>
          <el-radio-button label="其他" class="classification-button">其他</el-radio-button>
        </el-radio-group>
      </div>
      <div class="selectedClassification">
        <el-select v-model="selectedButton" placeholder="-------请选择课程分类-------">
          <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div>
      <el-divider></el-divider>
    </div>
    <div class="courseList">
      <div v-if="courseRecords.length===0" style="width: 100%"><el-empty description="暂无相关课程" :image-size=300 ></el-empty></div>
<!--      卡片布局 一行最多四个-->
      <div v-for="(item, index) in courseRecords" :key="index" class="card" @click="pushDetail(item.courseId)">
        <div class="card-image-container">
          <img :src=item.fileName alt="Course Image" class="card-img">
          <div class="status-uncompleted" v-if="item.isCompleted===0">招生中</div>
          <div class="status-completed" v-else>已完结</div>
        </div>

        <div class="card-content">
          <h4 class="course-title">{{item.courseName}}</h4>
          <p class="instructor-name">{{item.teacherName}}</p>
        </div>
      </div>

    </div>
    <div class="pageNation" style="padding-top: 20px;">
      <el-pagination
          @current-change="handleCurrentChange"
          background
          layout="prev, pager, next"
          :current-page="currentPage"
          :page-size="pageSize"
          :page-count="totlePage">
      </el-pagination>
    </div>
  </div>
</template>


<script>
import methods from "methods";
import request from "@/utils/request";

export default {
  name:'DisplayCourse',
  props: {
    courseType: {
      type: String,
      required: false

    }
  },
  data(){
    return{
      totlePage:1,
      pageSize:12,
      currentPage:1,
      courseRecords:[],
      selectedButton:'in',
      options: [{
        value: 'all',
        label: '全部'
      },
        {
          value: '研究计划书',
          label: '研究计划书'
        },
        {
        value: '大学院文科',
        label: '大学院文科'
      }, {
        value: '大学院理科',
        label: '大学院理科'
      },  {
        value: '学部文科',
        label: '学部文科'
      },
        {
          value: '学部理科',
          label: '学部理科'
        },
        {
          value: '艺术',
          label: '艺术'
        },
        {
          value: '日语',
          label: '日语'
        },
        {
          value: '英语',
          label: '英语'
        },
        {
          value: '其他',
          label: '其他'
        }
      ],
      value: '全部',
      watchDisable: false
    }
  },
  methods:{
    handleCurrentChange(index){
      this.displayCourse(index,this.pageSize,this.selectedButton)
    },
    async displayCourse(pageNow,pageSize,classify='all'){
      await request.get("course/listAllCourse?param1="+pageNow+"&param2="+pageSize+"&param3="+classify).then(res=>{
        if (res.data.code==='200'){
          console.log(res.data)
          let record = res.data.data
          this.totlePage = record.pages
          this.courseRecords = record.records
          this.courseRecords.forEach(item=>{
            item.fileName = 'https://d27dg1guodsgxw.cloudfront.net/'+item.fileName
            // if (item.fileName!=null){
            //   item.fileName = 'https://dreamease-course-cover.s3.ap-northeast-1.amazonaws.com/'+item.fileName
            // }else {
            //   const coverIndex = item.courseId %5 +1
            //   item.fileName = 'https://courseimagedreamease.s3.ap-northeast-1.amazonaws.com/courseCover/cover'+coverIndex+'.jpg'
            // }

          })
          this.currentPage = pageNow
        }


      }).catch(error=>{
        console.log(error)
      })
    },
    async displayCourseNew(pageNow,pageSize,classify='all'){
      await request.get("course/listAllCourse?param1="+pageNow+"&param2="+pageSize+"&param3="+classify).then(res=>{
        if (res.data.code==='200'){
          console.log(res.data)
          let record = res.data.data
          this.totlePage = record.pages
          this.courseRecords = record.records
          this.courseRecords.forEach(item=>{
            item.fileName = 'https://d27dg1guodsgxw.cloudfront.net/'+item.fileName
            // if (item.fileName!=null){
            //   item.fileName = 'https://dreamease-course-cover.s3.ap-northeast-1.amazonaws.com/'+item.fileName
            // }else {
            //   const coverIndex = item.courseId %5 +1
            //   item.fileName = 'https://courseimagedreamease.s3.ap-northeast-1.amazonaws.com/courseCover/cover'+coverIndex+'.jpg'
            // }

          })
          this.currentPage = pageNow
        }


      }).catch(error=>{
        console.log(error)
      })
    },
    pushDetail(id){
      // alert(id)
      //this.$router.push('/courseDetail/'+id);
      const newPage = this.$router.resolve({ path: '/courseDetail/'+id}).href
      window.open(newPage,'_blank')
    },
    handleResize() {
      const width = window.innerWidth;
      if (width >= 1152 && width <= 1434) {
        this.pageSize = 15;
      } else {
        // 可以在这里设置其他条件下的pageSize
        this.pageSize = 12
      }
      //this.displayCourse(1,this.pageSize);
    }
  },
  mounted() {

    console.log(this.$route.query.courseType,"courseType")
    // window.addEventListener('resize', this.handleResize);
    // this.handleResize();  // 初始检查
    //this.displayCourse(1,this.pageSize)
    if (this.$route.query.courseType === undefined){
      this.selectedButton = 'all'
    }else {
      this.selectedButton = this.$route.query.courseType
    }

    // if (this.courseType==='0'){
    //   this.displayCourse(1,this.pageSize);
    // }
    // else{
    //   this.displayCourse(1,this.pageSize,this.courseType);
    // }

  },
  destroyed() {
    //window.removeEventListener('resize', this.handleResize);
  },
  watch:{
    selectedButton(newVal, oldVal){
      this.displayCourse(1,this.pageSize,newVal);
    },

    // async courseType(newVal, oldVal) {
    //    this.selectedButton = newVal
    //    this.value = newVal
    //
    //   // if (newVal !==undefined && oldVal ==="0"){
    //   //   if (newVal === "1" && oldVal ==="0"){
    //   //     this.watchDisable = true
    //   //     this.selectedButton = "研究计划书"
    //   //     this.value = "研究计划书"
    //   //   }
    //     // else {
    //     //   this.selectedButton = newVal
    //     //   this.value = newVal
    //     // }
    //   // }
    // }
  }
}
</script>

<style>
.classification-button.is-active > .el-radio-button__inner:hover {
  color: white !important;
}
.el-radio-button__inner:hover {
  color: #FF8C00 !important;
}
.el-pager > .number.active{
  background-color: #FF8C00 !important;
}

.classification{
  display: flex;
  flex-wrap: wrap;
}
.item{
  margin:0 auto;
}

.courseList {
  display: flex;
  flex-wrap: wrap;
  justify-content: start; /* 或使用space-between根据喜好调整间距 */
}

.card {
  flex: 0 0 calc(24% - 10px); /* 一行显示4个卡片, 减去的值为间隙的一半 */
  margin-bottom: 15px;
  margin-left: 20px;
  cursor: pointer;
  overflow: hidden;
}
.card:hover{
  transform: scale(1.05); /* 轻微放大 */
  transition: transform 0.3s ease-in-out; /* 添加过渡动画使效果更平滑 */
}

.card-img {
  width: 100%; /* 图片宽度 */
  height: auto; /* 保持图片原有的长宽比 */
  aspect-ratio: 16 / 9;
  object-fit: cover; /* 确保图片填满容器 contain*/
  overflow: hidden;
}

.card-content {
  padding: 10px;
}
.selectedClassification{
  display: none;
}
.pageNation{
  margin-bottom: 30px;
  text-align: center;
}
.card-image-container {
  position: relative;
}

.status-uncompleted {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #42b242; /* 你可以根据需要调整背景颜色 */
  color: white;
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 0 0 5px 0; /* 你可以根据需要调整边框圆角 */
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5); /* 侧边阴影 */
}
.status-completed {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8); /* 你可以根据需要调整背景颜色 */
  color: white;
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 0 0 5px 0; /* 你可以根据需要调整边框圆角 */
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5); /* 侧边阴影 */
}
/* 电脑端和平板端 */

@media (max-width: 1434px){
  .card {
    flex: 0 0 calc(32% - 10px); /* 一行显示4个卡片, 减去的值为间隙的一半 */
    margin-bottom: 15px;
    margin-left: 20px;
  }
}
@media (max-width: 1152px) { /* 这里的768px是常见的断点之一，可以根据实际需求调整 */

  .card {
    flex: 0 0 calc(50% - 10px); /* 默认为手机视图, 减去的值为间隙的一半 */
    margin: 5px; /* 卡片之间的间距 */
    box-shadow: 0 2px 4px rgba(0,0,0,0.15); /* 可选的阴影效果增加立体感 */
  }
  .item{
    display: none;
  }
  .selectedClassification{
    display: block;
    margin: auto;
  }
}

</style>
