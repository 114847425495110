<template>
  <div class="home">
    <HeaderTest :message="true" :pwd="'1'"></HeaderTest>
    <div class="banner1Web" style="margin-top: 48px;height: 800px">
      <el-carousel height="800px" :interval="5000">
        <el-carousel-item v-for="(item,index) in imageList" :key="index" >
          <img :src=item  alt="平台介绍" style="height: 800px;width: 100vw;overflow-x: hidden;object-fit: cover">
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="banner1Phone" style="margin-top: 48px;">
      <el-carousel height="335px" :interval="5000">
        <el-carousel-item v-for="(item,index) in imageListPhone" :key="index" >
          <img :src=item  alt="平台介绍" style="width: 100%;height: 335px">
        </el-carousel-item>
      </el-carousel>
    </div>
<!--    <img src="../assets/homePageBanner.png" alt="topbanner" class="banner1Web" style="margin-top: 48px">-->
<!--    <img src="../assets/banner1Phone.png" alt="topbanner" class="banner1Phone" style="margin-top: 48px">-->
    <div class="latestCourses">
      <div class="latestCoursesItem">
        <p >热门课程</p>
        <p>LATEST COURSES</p>
      </div>
      <div class="latestCoursesItem" id="moreButton" @click="linkCoursePage">
        <button>更多课程 <i class="el-icon-top-right" style="font-weight: 850"></i> </button>
      </div>
    </div>
<!--    <div style="overflow: hidden">-->
      <div class="latestCourses_example" ref="courseContainer" @scroll="handleScroll">
        <div class="example" v-for="(item,index) in latestCourseList" :key="index" @click="linkcourseDetail(item.courseId)">
          <div class="image-container">
            <img :src="item.file" alt="course-cover" class="course-cover">
          </div>
          <p class="example_title">{{item.courseName}}</p>
          <div class="examle_labelContainer">
            <span class="example_label">{{item.label1}}</span>
            <span class="example_label">{{item.label2}}</span>
            <span class="example_label">{{item.label3}}</span>
          </div>
          <div class="example_infoBox">
            <p>{{item.teacherName}}</p>
            <p >{{item.lessonPrice}}</p>
          </div>
        </div>
      </div>
    <div class="phone_pictureshow">
      <div class="indicator-dots">
        <span v-for="(item, index) in latestCourseList" :key="index" :class="{ active: currentIndex === index }" class="dot"></span>
      </div>
      <span class="moreCourses" @click="linkCoursePage" >更多课程 >></span>
<!--        <button>更多课程 <i class="el-icon-top-right" style="font-weight: 850"></i> </button>-->
    </div>


    <div class="latestCourses">
      <div class="latestCoursesItem">
        <p >研究计划书</p>
        <p>RESEAECH PROPOSAL</p>
      </div>
      <div class="latestCoursesItem" id="moreButton" @click="linkResearchPage">
        <button>更多课程 <i class="el-icon-top-right" style="font-weight: 850"></i> </button>
      </div>
    </div>
    <!--    <div style="overflow: hidden">-->
    <div class="latestCourses_example" ref="courseContainer1" @scroll="handleScroll1">
      <div class="example" v-for="(item,index) in reserachList" :key="index" @click="linkcourseDetail(item.courseId)">
        <div class="image-container">
          <img :src="item.file" alt="course-cover" class="course-cover">
        </div>
        <p class="example_title">{{item.courseName}}</p>
        <div class="examle_labelContainer">
          <span class="example_label">{{item.label1}}</span>
          <span class="example_label">{{item.label2}}</span>
          <span class="example_label">{{item.label3}}</span>
        </div>
        <div class="example_infoBox">
          <p>{{item.teacherName}}</p>
          <p >{{item.lessonPrice}}</p>
        </div>
      </div>
    </div>
    <div class="phone_pictureshow">
      <div class="indicator-dots">
        <span v-for="(item, index) in reserachList" :key="index" :class="{ active: currentIndex1 === index }" class="dot"></span>
      </div>
      <span class="moreCourses" @click="linkResearchPage">更多课程 >></span>
      <!--        <button>更多课程 <i class="el-icon-top-right" style="font-weight: 850"></i> </button>-->
    </div>
    <div style="background-color: #F6F7FB" class="aboutUsContainer">
      <div class="aboutUsDiv">
        <img src="../assets/waseda.png" alt="about_us" class="aboutUsImgWeb">
        <div class="aboutUsText">
          <p>关于我们</p>
          <p>ABOUT US</p>
          <div class="aboutUsInfo">DREAMEASE，一款专注日本考学的创新型平台，立志于将想要来日本考学的学生和可以为其提供专业指导的老师进行直接连接，以突破以往通过私塾或中介的传统考学方式。我们致力于帮助学生突破经济门槛的同时，少走弯路，提高考学效率，轻松实现留日梦想！</div>
          <img src="../assets/waseda.png" alt="about_us" class="aboutUsImgphone">
          <div>
            <button @click="linkAboutUs">了解更多 <i class="el-icon-top-right" style="font-weight: 850"></i></button>
          </div>
        </div>

      </div>
    </div>
    <div class="reason">
      <p class="reasonp1">为什么选择我们</p>
      <p class="reasonp2" >WHY CHOOSE US</p>
      <div>
        <div  class="reason_container">
          <div class="reason_card" style="margin-right: 76px">
            <div class="titleContainer">
              <img src="../assets/reason1.png" alt="reason">
              <p class="reason_title">海量名师供你挑选</p>
            </div>

            <div class="reason_info">没有最好的老师，只有最合适的老师。我们集结了来自不同教育背景且拥有丰富考学辅导经验的优秀老师。每位老师都有其独特的教学风格，擅长的专业领域、考学院校和研究室。在这里，您一定可以在这里找到满足您需求的理想导师</div>
          </div>
          <div class="reason_card">
            <div class="titleContainer">
              <img src="../assets/reason2.png" alt="reason">
              <p class="reason_title">无中间商赚差价</p>
            </div>

            <div class="reason_info">我们立志于将老师与学生进行直接连接，打破中间商赚巨额差价的传统价值链。因此，在我们平台上直接接受老师辅导的费用远低于市面上的机构费用，以此帮助因高昂的机构费用而放弃留学梦想的学生突破经济门槛，自由勇敢地追求他们的留学梦。</div>
          </div>
        </div>
        <div class="reason_container">
          <div class="reason_card" style="margin-right: 76px">
            <div class="titleContainer">
              <img src="../assets/reason3.png" alt="reason">
              <p class="reason_title">按次付费机制</p>
            </div>
            <div class="reason_info">为了解决传统机构需一次性支付全部课时费用、高试错成本的痛点，我们提供按每课时付款的机制。此外，我们特别推荐学生灵活搭配老师和课程，以全面加深对知识的理解，从而提升考学效率。</div>
          </div>
          <div class="reason_card">
            <div class="titleContainer">
              <img src="../assets/reason4.png" alt="reason">
              <p class="reason_title">安全透明的平台维护机制</p>
            </div>
            <div class="reason_info">我们采用公开透明的讲师评价机制，学生可以查看各位教师的真实评价，结合自己的需求喜好进行选课。此外，平台严格控制交易流程，以避免师生之间的金钱纠纷，保障师生双方的权益。</div>
          </div>
        </div>
      </div>

    </div>
    <div style="background-color: #F6F7FB;">
      <div class="prominent_teacher">
        <p class="reasonp1">
          优秀教师</p>
        <p class="reasonp2" id="prominent_teacherp2" style="margin-bottom: 80px">
          PROMINENT TEACHER</p>
        <div class="teacher_example_pc">
          <div v-for="(item,index) in goodTeacherInfo" :key="index" class="teacher_example_item">
            <div class="profile_container">
<!--              <img src="../assets/profile_background.png" class="profile_background">-->
              <img :src="item.profile" alt="profile">
<!--              <div class="profile_background" style="z-index: 0"></div>-->
            </div>

            <p class="teacherName">{{item.teacherName}}</p>
            <p class="teacherDes">{{item.description}}</p>
          </div>
        </div>
<!--        <div class="teacher_example_phone">-->
<!--          <div v-for="(item,index) in goodTeacherInfo" :key="index" class="teacher_example_item_phone">-->
<!--            <div class="profile_container_phone">-->
<!--              &lt;!&ndash;              <img src="../assets/profile_background.png" class="profile_background">&ndash;&gt;-->
<!--              <img :src="item.profile" alt="profile_phone">-->
<!--              &lt;!&ndash;              <div class="profile_background" style="z-index: 0"></div>&ndash;&gt;-->
<!--            </div>-->

<!--            <p class="teacherName_phone">{{item.teacherName}}</p>-->
<!--            <p class="teacherDes_phone">{{item.description}}</p>-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>
    <div class="discussion">
      <p class="reasonp1">
        大家这样说</p>
      <p class="reasonp2" style="margin-bottom: 30px">
        THAT'S WHAT EVERYONE SAYS</p>
      <div class="discussions">
        <div v-for="(item,index) in discussionList" :key="index" class="discussionItem">
          <p>{{item.title}}</p>
          <div>{{item.content}}</div>
          <p style="padding-top: 30px;">{{item.username}}</p>
        </div>
      </div>
    </div>
    <div class="bottomBanner homeBottomBanner">
      <p>DREAMEASE 专注日本考学的创新教育平台</p>
      <p>DREAMEASE<br>专注日本考学的创新教育平台</p>
      <button @click="linkCoursePage" style="cursor: pointer">开始学习</button>
    </div>
    <bottom-banner></bottom-banner>




<!--    </div>-->

<!--    <container-test :showLn="ln"></container-test>-->
  </div>
</template>

<script>
// @ is an alias to /src


import * as assert from "assert";

export default {
  name: 'HomeView',
  components: {
    HeaderTest:()=> import('../components/HeaderTest.vue'),
    ContainerTest:()=> import('../components/Container.vue'),
    BottomBanner:()=> import('../components/BottomBanner.vue')
  },
  mounted() {
    if (this.$route.params.ln==='1'){
      this.ln = true
      console.log(this.ln)
    }
    this.$nextTick(() => {
      const container = this.$refs.courseContainer;
      const secondItem = container.children[1];
      if (secondItem) {
        const offset = secondItem.offsetLeft - (container.clientWidth / 2) + (secondItem.clientWidth / 2);
        container.scrollLeft = offset;
      }
    });
    this.$nextTick(() => {
      const container1 = this.$refs.courseContainer1;
      const secondItem = container1.children[1];
      if (secondItem) {
        const offset = secondItem.offsetLeft - (container1.clientWidth / 2) + (secondItem.clientWidth / 2);
        container1.scrollLeft = offset;
      }
    });
  },
  data(){
    return{
      showSearch: true,
      ln: false,
      imageList:[
          require("@/assets/homePageBanner.png"),
          require("@/assets/homePageBanner2.png")
      ],
      imageListPhone:[
        require("@/assets/banner1Phone.png"),
        require("@/assets/banner2Phone.png")
      ],
      latestCourseList:[
        {courseName:"経営戦略入門",teacherName:"小林老师",label1:"大学院文科",label2:"入门",label3:"经营学",lessonPeriod:"6课时",lessonPrice:"100元",courseId:78,
         file:"https://d27dg1guodsgxw.cloudfront.net/%E7%B5%8C%E5%96%B6%E6%88%A6%E7%95%A5%E5%85%A5%E9%96%80_1530554a-0347-4419-91fa-a8715ccb3b3e"},
        {courseName:"东京大学电气电子修考规划辅导",teacherName:"乃易老师",label1:"大学院理科",label2:"全程DIY",label3:"东京大学eeis",lessonPeriod:"课时可咨询",lessonPrice:"150元",courseId:143,
        file:"https://d27dg1guodsgxw.cloudfront.net/%E4%B8%9C%E4%BA%AC%E5%A4%A7%E5%AD%A6%E7%94%B5%E6%B0%94%E7%94%B5%E5%AD%90%E4%BF%AE%E8%80%83%E8%A7%84%E5%88%92%E8%BE%85%E5%AF%BC_07ececcf-2f12-4b48-b6cd-f84e5ecc4533"},
        {courseName:"媒体学升学咨询+试讲",teacherName:"Arata",label1:"过去问指导",label2:"面试对策",label3:"研究计划书",lessonPeriod:"3课时",lessonPrice:"60元",courseId:139,
        file:"https://d27dg1guodsgxw.cloudfront.net/%E3%80%90%E5%AA%92%E4%BD%93%E5%AD%A6%E3%80%91%E5%8D%87%E5%AD%A6%E5%92%A8%E8%AF%A2%2B%E8%AF%95%E8%AE%B2_a216e565-9d2d-40b6-8b82-6843457c9cf3"},
        {courseName:"SGU升学指导",teacherName:"周周不想上班",label1:"东北大学博士",label2:"研究计划书撰写",label3:"无一被拒",lessonPeriod:"5课时",lessonPrice:"50元",courseId:137,
        file: "https://d27dg1guodsgxw.cloudfront.net/%E7%90%86%E7%B3%BBSGU_7b6d3295-df7e-4ab8-bd9e-6bb63c42ee15"},
      ],
      currentIndex: 1, // 初始化为第二张卡片
      currentIndex1: 1, // 初始化为第二张卡片
      reserachList:[
        {courseName:"理工科大学院研究计划书面试辅导",teacherName:"Yang",label1:"东大研究员",label2:"早大博士",label3:"面试练习",lessonPeriod:"6课时",lessonPrice:"400元",courseId:117,
          file:"https://d27dg1guodsgxw.cloudfront.net/%E7%90%86%E5%B7%A5%E7%A7%91%E5%A4%A7%E5%AD%A6%E9%99%A2%E7%A0%94%E7%A9%B6%E8%AE%A1%E5%88%92%E4%B9%A6%E8%BE%85%E5%AF%BC_18f07ce5-b596-4786-9cf7-42772d86d7d4"},
        {courseName:"艺术类作品集·研究计划书·面试辅导",teacherName:"haku40",label1:"东大修士",label2:"情报设计",label3:"社会创新",lessonPeriod:"课时可咨询",lessonPrice:"500元",courseId:108,
          file:"https://d27dg1guodsgxw.cloudfront.net/cover4.jpg"},
        {courseName:"【经营学】研究计划书辅导课",teacherName:"熊熊MO",label1:"大学院理科",label2:"81人合格",label3:"独家资料",lessonPeriod:"3课时",lessonPrice:"210元",courseId:89,
          file:"https://d27dg1guodsgxw.cloudfront.net/cover5.jpg"},
        {courseName:"大学院研究计划书辅导",teacherName:"唐唐老师",label1:"研究计划书修改",label2:"代写",label3:"60+成功案例",lessonPeriod:"5课时",lessonPrice:"450元",courseId:140,
          file: "https://d27dg1guodsgxw.cloudfront.net/courseCover-140c0a7b499-0ecc-40f6-915a-16c6a97d4c51"},
      ],
      goodTeacherInfo:[
        {teacherName:"化学胡老师",description:"国内顶尖985毕业，现东大在读，辅导大学院学生成功升学帝大20+",profile:require("@/assets/hu.jpg")},
        {teacherName:"周周不想上班",description:"东北大博士，资深理科申请人，申请帝大超过20+ 提供从套磁，研究计划书到面试准备的全栈指导",profile:require("@/assets/zhou.jpg")},
        {teacherName:"小林老师",description:"擅长制定考学战略和管理学习进度，教学经验丰富，DreamEase人气讲师",profile:require("@/assets/xiaolin.png")},
        {teacherName:"小牛老师",description:"熟悉日本学部考学流程，面试辅导，志望理由书",profile:require("../assets/xiaoniu.jpg")},
      ],
      discussionList:[
        {title:"助力日本考学",content:"感谢DreamEase让我遇到了很多优秀的大佬讲师，他们对我的专业知识学习提供了巨大的帮助。特别是这些讲师都是来自各大名校的前辈，所以还分享给我很多梦校内部的宝贵信息，让我对考学的目标变得更加明确。",username:"学生用户"},
        {title:"学生教师直连",content:"作为考学过来人深知这种良心平台的含金量，如果当初考学的时候就遇到DreamEase就好了（哭。现在作为平台入驻老师，希望可以用自己的经验帮助有想法有目标的后辈们考上心仪的学校！希望平台越来越好，加油！",username:"教师用户"},
        {title:"去中介化",content:"以前日本备考几乎只有报私塾这一种途径。之前在小红书上看了很多人不推荐报私塾，就一直在犹豫。感谢DreamEase提供了一个老师和学生直连的平台，为日本留学的备考提供了更多的可能性。",username:"学生用户"}
      ]

    }
  },
  methods:{
    linkCoursePage(){
      this.$router.push("/courses")
    },
    linkResearchPage(){
      this.$router.push({ path: '/courses', query: { courseType: "研究计划书" } })
    },
    linkcourseDetail(courseId){
      const newPage = this.$router.resolve({ path: '/courseDetail/'+courseId}).href
      window.open(newPage,'_blank')
    },
    linkAboutUs(){
      this.$router.push("/about")
    },
    handleScroll() {
      const container = this.$refs.courseContainer;
      const children = container.children;
      let closestIndex = 0;
      let closestDistance = Infinity;

      for (let i = 0; i < children.length; i++) {
        const child = children[i];
        const childCenter = child.offsetLeft + (child.clientWidth / 2);
        const containerCenter = container.scrollLeft + (container.clientWidth / 2);
        const distance = Math.abs(childCenter - containerCenter);

        if (distance < closestDistance) {
          closestDistance = distance;
          closestIndex = i;
        }
      }

      this.currentIndex = closestIndex;
    },
    handleScroll1() {
      const container = this.$refs.courseContainer1;
      const children = container.children;
      let closestIndex = 0;
      let closestDistance = Infinity;

      for (let i = 0; i < children.length; i++) {
        const child = children[i];
        const childCenter = child.offsetLeft + (child.clientWidth / 2);
        const containerCenter = container.scrollLeft + (container.clientWidth / 2);
        const distance = Math.abs(childCenter - containerCenter);

        if (distance < closestDistance) {
          closestDistance = distance;
          closestIndex = i;
        }
      }

      this.currentIndex1 = closestIndex;
    }
  }
}
</script>
<style scoped>
.home{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}
.latestCourses{
  height: 150px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
.latestCoursesItem{
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #1A1A1A;
}
.latestCoursesItem p:nth-child(1){
  font-family: Source Han Sans CN sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 45px;
}
.latestCoursesItem p:nth-child(2){
  font-family: Source Han Sans CN sans-serif;
  font-size: 14px;
  font-weight: 350;
  line-height: 21px;
  letter-spacing: 0.05em;
  text-align: center;
}
.latestCoursesItem  button{
  width: 160px;
  height: 44px;
  border-radius: 8px;
  border: 0 solid transparent;
  background: linear-gradient(268.09deg, #F6AD53 7.48%, rgba(235, 93, 23, 0) 92.63%);
  color: #EB5D17;
  font-family: PingFang SC sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  cursor: pointer;
}

.latestCourses_example{
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding-bottom: 30px;
}
.example{
  width: 280px;
  padding-bottom: 20px;
  border: 1px solid #dddddd; /* 可选：添加边框 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 添加阴影 */
  border-radius: 10px;
  cursor: pointer;
}
.example:hover{
  transform: scale(1.05); /* 轻微放大 */
  transition: transform 0.3s ease-in-out; /* 添加过渡动画使效果更平滑 */
}
.image-container {
  height: 180px;
  overflow: hidden; /* 确保超出的部分被隐藏 */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 1 280px;
}

.course-cover {
  width: 100%;
  height: 100%;
  object-fit: cover; /* 等比例缩小 */
   border-radius: 10px;
}
.example_title{
  font-family: "Roboto","Microsoft YaHei","黑体","宋体",sans-serif;
  font-size: 16px;
  font-weight: 550;
  line-height: 22.4px;
  text-align: left;
  color: #1A1A1A;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 10px;

}
.examle_labelContainer{
  padding-left: 10px;
}
.example_label{
  font-family: 'PingFang SC', 'Helvetica Neue', Arial, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  border: 1px solid #EB5D17;
  color: #EB5D17;
  border-radius: 4px;
  padding: 3px 2px 3px 2px;
  margin-right: 10px;

}
.example_infoBox{
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #D9D9D9;
  padding-left: 10px;
}
.example_infoBox p:nth-child(1){
  font-family: 'PingFang SC', 'Helvetica Neue', Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.6px;
}
.example_infoBox p:nth-child(2){
  font-family: 'PingFang SC', 'Helvetica Neue', Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  width: fit-content;
  color: #EB5D17;
  background-color: #FEE8C7;
  padding: 3px 2px 3px 2px;
  margin-top: 5px;
}
.aboutUsDiv{
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  height: 506px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.aboutUsDiv img{
  width: 440px;
  height: 306px;
  border-radius: 20px;
}
.aboutUsDiv .aboutUsText{
  width: 660px;

}

.aboutUsText p:nth-child(1){
  font-family: Alibaba PuHuiTi sans-serif;
  font-size: 30px;
  font-weight: bold;
  line-height: 41.16px;
}
.aboutUsText p:nth-child(2){
  font-family: Source Han Sans CN sans-serif;
  font-size: 14px;
  font-weight: 350;
  line-height: 20px;
  letter-spacing: 0.05em;
}
.aboutUsInfo{
  font-family: Alibaba PuHuiTi sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  margin: 40px 0;


}
.aboutUsDiv button{
  width: 126px;
  height: 44px;
  border-radius: 8px;
  border: 0 solid transparent;
  color: white;
  font-size: 16px;
  font-weight: 400;
  font-family: PingFang SC, sans-serif;
  background-color: #EB5D17;
  cursor: pointer;
}
.reason{
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 98px;
  padding-bottom: 80px;
}
.reasonp1{
  font-family: Source Han Sans CN sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 45px;
  text-align: center;
}
.reasonp2{
  font-family: Source Han Sans CN sans-serif;
  font-size: 14px;
  font-weight: 350;line-height: 21px;
  text-align: center;
}
.reason_container{
  display: flex;
  justify-content: center;
  padding-top: 80px;
}
.reason_card{
  width: 402px;
  height: 400px;
  border-radius: 20px;
  border: 2px solid #FFFFFF;
  box-shadow: 0px 30px 90px 5px rgba(135, 167, 247, 0.3);
  padding-left: 40px;
  background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.3) 100%);
}
.reason_card img{
  width: 60px;
  height: 60px;
  margin-top: 80px;
  margin-bottom: 40px;
  border-radius: 10px;
  background-color: #FEF7F2;
}
.reason_title{
  font-family: Alibaba PuHuiTi sans-serif;
  font-size: 18px;
  font-weight: bold;
  line-height: 24.7px;
}
.reason_info{
  font-family: Alibaba PuHuiTi sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  padding: 20px 40px 40px 0;

}
.reason_container .reason_card:hover,
.reason_container .reason_card:focus,
.reason_container .reason_card:hover,
.reason_container .reason_card:focus {
  transform: translateY(-30px);
  background: linear-gradient(120.97deg, #FD7735 45.18%, #F6AD53 101.81%);
  box-shadow: 0px 30px 90px 5px #87A7F742;
  color: white;
}
.prominent_teacher{
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 98px;
  height: 700px;
}
.teacher_example_pc{
  display: flex;
  justify-content: space-around;
}
.teacher_example_item{
  width: 270px;
  height: 347px;
}
.teacher_example_item img{
  width: 100%;
  height: 270px;
  border-radius: 135px ;
  display: block;
  box-shadow: 0px 30px 90px 5px #87A7F742;
}
.profile_container{
  position: relative;
}
.profile_background {
  position: absolute;
  top: 20px; /* Adjust as needed */
  right: -15px; /* Adjust as needed */
  width: 270px;
  height: 270px;
  border-radius: 130px;
  z-index: 0;
}
.teacherName{
  font-family: Alibaba PuHuiTi sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 21.95px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
}
.teacherDes{
  font-family: Alibaba PuHuiTi sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.21px;
  text-align: center;
  color: #666666;
}
.teacher_example_pc .teacher_example_item:nth-child(1),
.teacher_example_pc .teacher_example_item:nth-child(3){
  margin-top: 135px;
}
.discussion{
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 98px;
}
.discussions{
  display: flex;
  background-image: url("../assets/sectionBackground.png");
  background-size: contain;  /* 覆盖整个div，也可设置为contain */
  background-position: center;  /* 图片居中显示 */
  background-repeat: no-repeat;  /* 不重复图片 */
  width: 100%;
  justify-content: space-between;

}
.discussionItem{
  width: 300px;
  border: 2px solid #FFFFFF;
  padding: 30px;
  box-shadow: 0px 30px 90px 5px #87A7F742;
}
.discussionItem p:nth-child(1){
  font-family: Alibaba PuHuiTi sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  margin-bottom: 30px;
}
.discussionItem div{
  font-family: Alibaba PuHuiTi sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  padding-bottom: 30px;
  color:  #555555;
  border-bottom: 1px solid #D9D9D9;
}
.discussionItem p:nth-child(2){
  font-family: Alibaba PuHuiTi sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 14px;
  padding-top: 30px;
}
.bottomBanner {
  background-image: url("../assets/bottomBackground.png");
  background-size: cover;  /* 将背景图片按照比例缩放 */
  background-position: center;  /* 图片居中显示 */
  background-repeat: no-repeat;  /* 不重复图片 */
  height: 244px;
  width: 100%;
  text-align: center;  /* 文字居中 */
  margin-top: 30px;

}
.bottomBanner p{
  font-family: Alibaba PuHuiTi sans-serif;
  font-size: 24px;
  font-weight: bold;
  line-height: 24px;
  color: white;
  margin-top: 70px;
}
.bottomBanner button{
  width: 104px;
  height: 40px;
  border-radius: 8px;
  background: #EB5D17;
  border: 2px solid #FFFFFF;
  color: white;
  font-family: Alibaba PuHuiTi sans-serif;
  font-size: 16px;
  line-height: 22px;
  margin-top: 40px;
}
.banner1Phone{
  display: none;
}
.aboutUsImgphone{
  display: none;
}
.bottomBanner p:nth-child(2){
  display: none;
}
.moreCourses{
  display: none;
}

@media screen and (max-width: 1000px) {
  .home{
    width: 100%;
  }
  .banner1Web{
    display: none;
  }
  .banner1Phone{
    display: block;
    height: 335px;
  }
  .latestCourses{
    height: 110px;
    justify-content: center;
  }
  .latestCoursesItem p:nth-child(1){
    font-size: 20px;
    line-height: 27px;
    text-align: center;
  }

  #moreButton{
    display: none;
  }
  .latestCourses_example{
    display: block;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    scroll-snap-type: x mandatory;
  }
  .example{
    display: inline-block;
    width: 260px;
    height: 340px;
    padding-bottom:0;
    border: 1px solid #dddddd; /* 可选：添加边框 */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 添加阴影 */
    border-radius: 10px;
    cursor: pointer;
    margin-right: 20px;
  }
  .latestCourses_example::-webkit-scrollbar{
    display: none;
  }

  .example_title{
    padding: 10px 0 5px 10px;
  }
  .example_infoBox{
    margin-top: 20px;
    padding-top: 10px;
    border-top: 1px solid #D9D9D9;
    padding-left: 10px;
  }
 .aboutUsContainer{
   margin-top: 30px;
   padding-bottom: 30px;
 }
  .aboutUsDiv{
    display: block;
    text-align: center;
    padding-top: 40px;
    height: fit-content;
  }
  .aboutUsText{
    width: 90% !important;
    margin: 0 auto;
  }
  .aboutUsInfo{
    margin: 20px 0;
    padding: 0;
    font-size: 14px;
    line-height: 30px;
  }
  .aboutUsText p:nth-child(1){
    font-size: 20px;
    line-height: 28px;
  }
  .aboutUsText p:nth-child(2){
    font-size: 12px;
    line-height: 20px;
  }

  .aboutUsImgWeb{
    display: none;
  }
  .aboutUsImgphone{
    display: block;
    width: 90% !important;
    height: 300px !important;
    margin: 0 auto;
    padding-bottom: 30px;
  }
  .aboutUsDiv img{
    width: 90%;
    height: 300px !important;
    border-radius: 20px;
  }
  .aboutUsDiv .aboutUsText{
    width: 90vw;
  }

.indicator-dots {
   display: flex;
   justify-content: center;
   margin-top: 10px;
 }

.dot {
  width: 10px;
  height: 10px;
  margin: 0 5px;
  background-color: #ddd;
  border-radius: 50%;
}

.dot.active {
  background-color: #F6AD53FF;
}
.phone_pictureshow{
  display: flex;
  justify-content: center;
  position: relative;

}
.moreCourses{
  display: block;
  position: absolute;
  right: 30px;
  padding-top: 3px;
  color: #EB5D17;
  font-size: 14px;
  cursor: pointer;
}
.reason{
  padding: 40px 0;
}
  .reasonp1{
    font-family: Source Han Sans CN sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;

  }
  .reasonp2{
    font-family: Source Han Sans CN sans-serif;
    font-size: 12px;
    line-height: 20px;
  }
  .reason_container{
    flex-direction: column;
    padding-top: 20px;
  }
  .reason_card{
    width: 90%;
    height: fit-content;
    padding: 0;
    margin: 0 auto !important;
    margin-bottom: 20px !important;
  }
  .titleContainer{
    margin-top: 30px;
    display: flex;
    align-items: center;
    margin-left: 20px;
  }
  .reason_card img{
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 20px;
  }
  .reason_info{
    margin-left: 25px;
    color: #777777;
    padding: 10px 40px 30px 0;
  }
  .reason_container .reason_card:hover,
  .reason_container .reason_card:focus,
  .reason_container .reason_card:hover,
  .reason_container .reason_card:focus {
    transform: none;
    background: none;
    box-shadow: none;
    color: inherit;
  }

  .prominent_teacher{
    padding-top: 10px;
    padding-bottom: 20px;
    height: fit-content;
  }
  #prominent_teacherp2{
    margin-bottom: 40px !important;
  }
  .teacher_example_pc{
    display: flex;
    flex-wrap: wrap;
  }
  .teacher_example_item{
    width: 35vw;
    height: fit-content;
    padding-bottom: 20px;
  }
  .teacher_example_item{
     margin-top: 0 !important;
   }

  .profile_container img{
    width: 100%;
    height: 35vw;
    border-radius: 17vw;
  }
  .discussion{
    padding-top: 30px;
  }
  .discussions{
    flex-direction: column;
    align-items: center;
  }
  .discussionItem{
    margin-bottom: 20px;
  }

  .bottomBanner p:nth-child(1){
    display: none;
  }
  .bottomBanner p:nth-child(2){
    display: block;
    font-weight: bold;
    margin-top: 70px;
    font-size: 17px;
    line-height: 30px;
  }
  .bottomBanner button{
    margin-top: 20px;
  }
  .homeBottomBanner{
    margin-bottom: 20px;
  }

}


</style>
