import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import "@/assets/style.css"
import 'default-passive-events'

//import { VueReCaptcha } from 'vue-recaptcha-v3'
Vue.use(ElementUI);
// For more options see below
//Vue.use(VueReCaptcha, { siteKey: '6Ld7EOApAAAAAOHsTchGC0jYmwJ-fz0ZYaVGVHQI' })


import VueRouter from "vue-router";

const routerPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if(onResolve || onReject) return routerPush.call(this, location, onResolve, onReject);
  return routerPush.call(this, location).catch((error) => error);
};

Vue.config.productionTip = false
import axios from 'axios';
Vue.prototype.$http = axios
axios.defaults.baseURL = 'http://localhost:8080'
new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
