import CryptoJS from "crypto-js";

export function decryptData(encryptedData) {
    if (!encryptedData) {
        return null;
    }
    try {
        const bytes = CryptoJS.AES.decrypt(encryptedData, process.env.VUE_APP_TOEKN_KEY);
        const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
        return JSON.parse(decryptedData);
    } catch (error) {
        console.error('Decrypt error:', error);
        return null;
    }
}

export function encryptData(data) {
    const jsonString = JSON.stringify(data);
    const encryptedData = CryptoJS.AES.encrypt(jsonString, process.env.VUE_APP_TOEKN_KEY).toString();
    return encryptedData;
}
