// import Vue from 'vue'
// import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'
// import Register from "@/components/subComponents/register.vue";
// import Publish from "@/components/Teacher/Publish.vue";
// import CourseDetail from "@/components/CourseDetail.vue";
// import MyCourse from "@/components/personalPage/MyCourse.vue";
// import LessonDetail from "@/components/LessonDetail.vue";
// import PaidCourse from "@/components/personalPage/PaidCourse.vue";
// import ManageCourse from "@/components/Teacher/ManageCourse.vue";
// import ManageLesson from "@/components/Teacher/ManageLesson.vue";
// import NotFound from "@/components/subComponents/NotFound.vue";
// import MessageCenter from "@/components/subComponents/messageCenter.vue";
// import myPage from "@/components/personalPage/myPage.vue";
// import searchCourse from "@/components/subComponents/searchCourse.vue";
// import CryptoJS from "crypto-js";
// import passwordReset from "@/components/subComponents/passwordReset.vue";
// import contactUs from "@/components/subComponents/contactUs.vue";
// import LessonDetailTeacher from "@/components/Teacher/LessonDetailTeacher.vue";
// import {encryptData,decryptData} from "@/utils/encryptUtils";
// import PlatformIntroduction from "@/components/carouselPage/PlatformIntroduction.vue";
// import sagreement from "@/components/subComponents/sagreement.vue";
// import tagreement from "@/components/subComponents/tagreement.vue";
// Vue.use(VueRouter)
//
// const routes = [
//   {
//     path: '/',
//     name: 'home',
//     component: HomeView,
//   },
//   {
//     path: '/register/:role',
//     name:'Register',
//     component: Register
//   },
//   {
//     path: '/publish',
//     name: 'Publish',
//     component: Publish
//   },
//   {
//     path:'/courseDetail/:id',
//     name:'CourseDetail',
//     component: CourseDetail
//   },
//   // {
//   //   path: '/lessonP/:id',
//   //   name:'LessonProcess',
//   //   component: LessonProcess
//   // },
//   {
//     path: '/lesson/:courseId/:lessonId',
//     name: 'LessonDetail',
//     component: LessonDetail
//   },
//   {
//     path: '/appliedstudent/:courseId/:lessonId',
//     name: 'LessonDetailTeacher',
//     component: LessonDetailTeacher
//   },
//   {
//     path: '/mycourse',
//     name:'MyCourse',
//     component: MyCourse
//   },
//   {
//     path: '/paidcourse',
//     name:'PaidCourse',
//     component: PaidCourse
//   },
//   {
//     path: '/managecourse',
//     name:'ManageCourse',
//     component: ManageCourse
//   },
//   {
//     path: '/managelesson/:courseId',
//     name: 'ManageLesson',
//     component: ManageLesson
//   },
//   {
//     path: '/notFound',
//     name: 'NotFound',
//     component: NotFound
//   },
//   {
//     path: '/message',
//     name: 'messageCenter',
//     component: MessageCenter
//   },
//   {
//     path: '/mypage',
//     name: 'myPage',
//     component: myPage
//   },
//   {
//     path: '/search/:keyword',
//     name: 'searchCourse',
//     component: searchCourse
//   },
//   {
//     path: '/password/reset',
//     name: 'passwordReset',
//     component: passwordReset
//   },
//   {
//     path: '/contactus',
//     name: 'contactUs',
//     component: contactUs
//   },
//   {
//     path: '/tagreement',
//     name: 'tagreement',
//     component: tagreement
//   },
//   {
//     path: '/sagreement',
//     name: 'sagreement',
//     component: sagreement
//   },
//   {
//     path: '/platformIntro',
//     name: 'PlatformIntroduction',
//     component: PlatformIntroduction
//   }
//
// ]
//
// const router = new VueRouter({
//   mode:'history',
//   routes,
//   scrollBehavior (to, from, savedPosition) {
//     // 总是滚动到顶部
//     return { x: 0, y: 0 };
//   }
// })
//
// // 添加全局路由守卫
// router.beforeEach((to, from, next) => {
//   //设置title
//   document.title = 'DREAMEASE'
//   // 从 localStorage 获取所需的信息
//   const userInfo = localStorage.getItem('honey-user');
//   const userData = decryptData(userInfo)
//   // 检查目标路径是否是 /publish 或符合 /lesson/:courseId/:lessonId 的模式
//   const lessonPathPattern = /^\/lesson\/\d+\/\d+$/;
//   //阻止老师
//   //lessonPathPattern.test(to.path) ||
//   if (lessonPathPattern.test(to.path) || to.path==="/paidcourse") {
//     if (!userData || userData.role === 'tea') {
//       return next('/'); // 阻止前往 /lesson 路径
//     }
//   }
//   //阻止学生
//   const appliedStudentPathPattern = /^\/appliedStudent\/\d+\/\d+$/;
//   if (appliedStudentPathPattern.test(to.path) || to.path === '/publish' || to.path.startsWith('/managecourse')) {
//     if (!userData || userData.role === 'stu') {
//       return next('/'); // 阻止前往 /publish 路径
//     }
//   }
//
//   next(); // 允许其他情况的路由跳转
// });
// export default router

import Vue from 'vue'
import VueRouter from 'vue-router'
import CryptoJS from "crypto-js";
import {encryptData, decryptData} from "@/utils/encryptUtils";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue'),
  },
  {
    path: '/register/:role',
    name:'Register',
    component: () => import(/* webpackChunkName: "register" */ "@/components/subComponents/register.vue")
  },
  {
    path: '/courses',
    name:'ContainerTest',
    component: () => import(/* webpackChunkName: "register" */ "@/components/Container.vue")
  },
  {
    path: '/publish',
    name: 'Publish',
    component: () => import(/* webpackChunkName: "publish" */ "@/components/Teacher/Publish.vue")
  },
  {
    path:'/courseDetail/:id',
    name:'CourseDetail',
    component: () => import(/* webpackChunkName: "courseDetail" */ "@/components/CourseDetail.vue")
  },
  {
    path:'/sensei/:id',
    name:'Sensei',
    component: () => import(/* webpackChunkName: "courseDetail" */ "@/components/personalPage/Sensei.vue")
  },
  {
    path: '/lesson/:courseId/:lessonId',
    name: 'LessonDetail',
    component: () => import(/* webpackChunkName: "lessonDetail" */ "@/components/LessonDetail.vue")
  },
  {
    path: '/appliedstudent/:courseId/:lessonId',
    name: 'LessonDetailTeacher',
    component: () => import(/* webpackChunkName: "lessonDetailTeacher" */ "@/components/Teacher/LessonDetailTeacher.vue")
  },
  {
    path: '/mycourse',
    name:'MyCourse',
    component: () => import(/* webpackChunkName: "myCourse" */ "@/components/personalPage/MyCourse.vue")
  },
  {
    path: '/paidcourse',
    name:'PaidCourse',
    component: () => import(/* webpackChunkName: "paidCourse" */ "@/components/personalPage/PaidCourse.vue")
  },
  {
    path: '/managecourse',
    name:'ManageCourse',
    component: () => import(/* webpackChunkName: "manageCourse" */ "@/components/Teacher/ManageCourse.vue")
  },
  {
    path: '/managelesson/:courseId',
    name: 'ManageLesson',
    component: () => import(/* webpackChunkName: "manageLesson" */ "@/components/Teacher/ManageLesson.vue")
  },
  {
    path: '/notFound',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "notFound" */ "@/components/subComponents/NotFound.vue")
  },
  {
    path: '/message',
    name: 'messageCenter',
    component: () => import(/* webpackChunkName: "messageCenter" */ "@/components/subComponents/messageCenter.vue")
  },
  {
    path: '/mypage',
    name: 'myPage',
    component: () => import(/* webpackChunkName: "myPage" */ "@/components/personalPage/myPage.vue")
  },
  {
    path: '/search/:keyword',
    name: 'searchCourse',
    component: () => import(/* webpackChunkName: "searchCourse" */ "@/components/subComponents/searchCourse.vue")
  },
  {
    path: '/password/reset',
    name: 'passwordReset',
    component: () => import(/* webpackChunkName: "passwordReset" */ "@/components/subComponents/passwordReset.vue")
  },
  {
    path: '/contactus',
    name: 'contactUs',
    component: () => import(/* webpackChunkName: "contactUs" */ "@/components/subComponents/contactUs.vue")
  },
  {
    path: '/tagreement',
    name: 'tagreement',
    component: () => import(/* webpackChunkName: "tagreement" */ "@/components/subComponents/tagreement.vue")
  },
  {
    path: '/sagreement',
    name: 'sagreement',
    component: () => import(/* webpackChunkName: "sagreement" */ "@/components/subComponents/sagreement.vue")
  },
  {
    path: '/about',
    name: 'PlatformIntroduction',
    component: () => import(/* webpackChunkName: "platformIntroduction" */ "@/components/carouselPage/PlatformIntroduction.vue")
  }
]

const router = new VueRouter({
  mode:'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    // 总是滚动到顶部
    return { x: 0, y: 0 };
  }
})

// 添加全局路由守卫
router.beforeEach((to, from, next) => {
  //设置title
  document.title = 'DREAMEASE'
  // 从 localStorage 获取所需的信息
  const userInfo = localStorage.getItem('honey-user');
  const userData = decryptData(userInfo)
  // 检查目标路径是否是 /publish 或符合 /lesson/:courseId/:lessonId 的模式
  const lessonPathPattern = /^\/lesson\/\d+\/\d+$/;
  //阻止老师
  //lessonPathPattern.test(to.path) ||
  if (lessonPathPattern.test(to.path) || to.path==="/paidcourse") {
    if (!userData || userData.role === 'tea') {
      return next('/'); // 阻止前往 /lesson 路径
    }
  }
  //阻止学生
  const appliedStudentPathPattern = /^\/appliedStudent\/\d+\/\d+$/;
  if (appliedStudentPathPattern.test(to.path) || to.path === '/publish' || to.path.startsWith('/managecourse')) {
    if (!userData || userData.role === 'stu') {
      return next('/'); // 阻止前往 /publish 路径
    }
  }

  next(); // 允许其他情况的路由跳转
});

export default router
