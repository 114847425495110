// import axios from "axios";
//
// import router from "@/router";
//
// const request = axios.create({
//     baseURL:"http://localhost:8080",
//     timeout:30000
// })
//
// //request拦截器
// //请求发送前，对请求进行处理。例如统一加token，对参数进行加密
// request.interceptors.request.use(config=>{
//     //config.headers['Content-Type'] = 'application/json;charset=utf-8';
//     let user = JSON.parse(localStorage.getItem("honey-user") || '{}')
//     console.log("user token:"+user.token)
//     config.headers.token = user.token
//
//     return config
// },error => {
//     console.error('myrequest error: ' + error)
//     return Promise.reject(error)
// })
//
// //response 拦截器
// //可以在接口响应后，统一处理结果
// request.interceptors.response.use(response=>{
//     let res = response.data;
//     //兼容服务器返回的字符串数据
//     if (typeof res === 'string'){
//         res = res ? JSON.parse(res): res
//     }
//     if (res.code === '401'){
//         // this.$notify({
//         //     title:'提示',
//         //     message:"请先登录",
//         //     type:'warning',
//         //     duration: 0
//         // })
//
//         router.push("/register")
//
//     }
//     return res
// },error => {
//     console.error("response error: "+ error)
//     return Promise.reject(error)
// })
//
// export default request

import axios from 'axios'
import router from '../router'
import CryptoJS from "crypto-js";
import { Message,MessageBox } from 'element-ui'
import {decryptData} from "@/utils/encryptUtils";
// 创建可一个新的axios对象 myLB-1215671826.ap-northeast-1.elb.amazonaws.com
const request = axios.create({
    //de-server.site https://localhost
    baseURL: 'https://de-server.site',   // 后端的接口地址  ip:port
    timeout: 30000
})


// request 拦截器
// 可以自请求发送前对请求做一些处理
// 比如统一加token，对请求参数统一加密
request.interceptors.request.use(config => {
    config.headers['Content-Type'] = 'application/json;charset=utf-8';
    // let user = JSON.parse(localStorage.getItem("honey-user") || '{}')
    let user = localStorage.getItem("honey-user")

    let deUser = decryptData(user);
    if (deUser && deUser.token) {
        config.headers.token = deUser.token;
    } else {
        console.warn('No valid token found');
        // 这里可以根据需要处理没有token的情况，比如跳转到登录页面或使用默认token等
    }
    //config.headers.token = user.token
    return config
}, error => {
    console.error('request error: ' + error) // for debug
    return Promise.reject(error)
});


// response 拦截器
// 可以在接口响应后统一处理结果
request.interceptors.response.use(
    response => {
        let res = response;
        //判断idtoken是否已经过期
        if (res.data.code==='401' && res.data.msg=='请登录'){
            MessageBox.confirm('<span class="courseExpireMessage2">未登录或登陆超时，请前往主页重新登陆</span>', '登陆', {
                confirmButtonText: '前往主页',type: 'warning',dangerouslyUseHTMLString: true,cancelButtonText: '取消'
            }).then(()=>{
                router.push('/')
                localStorage.clear()
            }).catch(()=>{

            })

        }
        if (res.data.code==='404' && res.data.msg=='NOT FOUND'){
            router.push('/notFound')
        }
        // 兼容服务端返回的字符串数据
        if (typeof res.data === 'string') {
            res = res ? JSON.parse(res.data) : res.data
        }
        return res;
    },
    error => {
        console.error('response error: ' + error) // for debug
        return Promise.reject(error)
    }
)


export default request
